<template>
  <div>
    <FiltriEconomatoSocieta
      @getEconomatoSocietaList="searchEconomatoSocList"
      @resetFilters="resetFilters"
    />
    <TableEconomatoSocieta
      @getEconomatoSocietaList="getEconomatoSocietaList"
      @resetFilters="resetFilters"
      :societa="societa"
      :launchSearch="launchSearch"
      :reset="reset"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { trimInput } from "@/composables/trimInput";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriEconomatoSocieta from "@/components/components-fit/società/economato/FiltriEconomatoSocieta.vue";
import TableEconomatoSocieta from "@/components/components-fit/società/economato/TableEconomatoSocieta.vue";

export default defineComponent({
  name: "economatoSocieta",
  components: {
    FiltriEconomatoSocieta,
    TableEconomatoSocieta,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(() => store.getters.rowsToSkipEconomatoSoc);
    const fetchRows = computed(() => store.getters.fetchRowsEconomatoSoc);
    const sortColumn = computed(() => store.getters.sortColumnEconomatoSoc);
    const sortOrder = computed(() => store.getters.sortOrderEconomatoSoc);

    const tipo_mov = computed(() => store.getters.tipoEconomatoSoc);
    const causale_nuova = computed(() => store.getters.causaleEconomatoSoc);
    const descrizione = computed(() => store.getters.descrizioneEconomatoSoc);
    const approvato = computed(
      () => store.getters.statoApprovazioneEconomatoSoc
    );
    const data_da = computed(() => store.getters.dataRegistDaEconomatoSoc);
    const data_a = computed(() => store.getters.dataRegistAEconomatoSoc);
    const data_appr_da = computed(() => store.getters.dataApprovDaEconomatoSoc);
    const data_appr_a = computed(() => store.getters.dataApprovAEconomatoSoc);
    const launchSearch = ref(0);

    const getEconomatoSocietaList = () => {
      launchSearch.value++;
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          stagione: stagione.value,
          tipo_mov: trimInput(tipo_mov.value),
          causale_mov: trimInput(causale_nuova.value),
          descrizione: descrizione.value,
          approvato: approvato.value,
          data_da: data_da.value,
          data_a: data_a.value,
          data_appr_da: data_appr_da.value,
          data_appr_a: data_appr_a.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.SOCIETA_ECONOMATO_LIST,
        itemName: "societa_economato_list",
      });
    };
    getEconomatoSocietaList();

    const reset = ref(false);
    const resetFilters = () => {
      store.commit("resetFiltersEconomatoSoc");
      getEconomatoSocietaList();
      reset.value = true;
      setTimeout(() => {
        reset.value = false;
      }, 1000);
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedsocieta_economato_list")
    );

    const searchEconomatoSocList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipEconomatoSoc");
      getEconomatoSocietaList();
      reset.value = true;
      setTimeout(() => {
        reset.value = false;
      }, 1000);
    };

    return {
      getEconomatoSocietaList,
      resetFilters,
      searchEconomatoSocList,
      launchSearch,
      reset,
    };
  },
});
</script>
